
a {
    font-family: Arial, Helvetica, sans-serif;
    text-decoration: none;
    color: black;
    background-color: rgba(255,255,255,1);
    transition: background-color 2500ms ease-out;
  }
  
  .selected {
    background-color: yellow;
    transition: background-color 250ms ease-in;
  }
  