.btn {
    border-radius: 4px;
    border-width: 0;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    letter-spacing: 0.08929em;
    text-transform: uppercase;
    text-decoration: none;
    height: 36px;
    min-width: 64px;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
    padding: 0 16px 0 16px;
    -webkit-font-smoothing: antialiased;
}

.btn:focus {
    outline: none;
}

.btn:hover {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.btn-primary {
    /* background-color: #6200ee; */
    background-color: #3160B6;
    color: white;
}

.btn-secondary {
    background-color: transparent;
    /* color: #6200ee; */
    color: #3160B6;
}

