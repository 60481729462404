.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
}

.form {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    min-width: 300px;
    max-width: 640px;
    border: 1px solid black;
    padding: 30px;
}

.formItem {
    margin: 10px;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #757575;
    display: block;
    padding: 2px;
}

.formItem:focus {
    outline: none;
}

.formItem::placeholder {
    text-transform: uppercase;
}